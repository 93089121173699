// src/firebase.js

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage"; // Importer getStorage
import { getAnalytics } from "firebase/analytics"; // Optionnel

const firebaseConfig = {
  apiKey: "AIzaSyA2b-CYPG6owGxUjtyj5fHE5yJrwHr74fM",
  authDomain: "trainotech-e19e2.firebaseapp.com",
  databaseURL: "https://trainotech-e19e2-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "trainotech-e19e2",
  storageBucket: "trainotech-e19e2.appspot.com",
  messagingSenderId: "116103532820",
  appId: "1:116103532820:web:44877dcb73d6ad4214f201",
  measurementId: "G-E8B8HCSHZW" // Optionnel
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app); // Initialiser Storage
const analytics = getAnalytics(app); // Optionnel

// Exporter les services pour les utiliser dans d'autres parties de l'application
export { auth, db, storage, analytics };
