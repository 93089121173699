// src/components/CandidateDetails.js
import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { doc, getDoc, getDocs, collection } from 'firebase/firestore';
import { useParams, Link } from 'react-router-dom';
import { Typography, Box, Button } from '@mui/material';
import { FaEdit, FaArrowLeft } from 'react-icons/fa';

const CandidateDetails = () => {
  const { id } = useParams();
  const [candidate, setCandidate] = useState(null);
  const [formations, setFormations] = useState([]);

  useEffect(() => {
    const fetchCandidate = async () => {
      try {
        const candidateDoc = await getDoc(doc(db, 'Candidates', id));
        if (candidateDoc.exists()) {
          const data = candidateDoc.data();
          setCandidate({ id: candidateDoc.id, ...data });
        } else {
          console.log("Aucun candidat trouvé !");
        }
      } catch (err) {
        console.error("Erreur lors de la récupération du candidat: ", err);
      }
    };

    const fetchFormations = async () => {
      try {
        const formationsSnapshot = await getDocs(collection(db, 'Formations'));
        const formationsData = formationsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setFormations(formationsData);
      } catch (err) {
        console.error("Erreur lors de la récupération des formations: ", err);
      }
    };

    fetchCandidate();
    fetchFormations();
  }, [id]);

  if (!candidate) {
    return <Typography variant="h6">Chargement des détails du candidat...</Typography>;
  }

  return (
    <Box sx={styles.container}>
      <Typography variant="h4" gutterBottom>{candidate.firstName} {candidate.lastName}</Typography>
      <Typography variant="subtitle1" gutterBottom>Email :</Typography>
      <Typography variant="body1" gutterBottom>{candidate.email}</Typography>
      <Typography variant="subtitle1" gutterBottom>Status :</Typography>
      <Typography variant="body1" gutterBottom>{candidate.status}</Typography>
      <Typography variant="subtitle1" gutterBottom>Voeux :</Typography>
      <Typography variant="body1" gutterBottom>
        {(candidate.voeux || []).map(voeuId => {
          const formation = formations.find(f => f.id === voeuId);
          return formation ? formation.title : voeuId;
        }).join(', ')}
      </Typography>
      <Box sx={styles.buttons}>
        <Button
          component={Link}
          to={`/candidates/edit/${candidate.id}`}
          variant="contained"
          color="secondary"
          startIcon={<FaEdit />}
          sx={styles.button}
        >
          Modifier
        </Button>
        <Button
          component={Link}
          to="/candidates"
          variant="outlined"
          color="primary"
          startIcon={<FaArrowLeft />}
          sx={styles.button}
        >
          Retour à la Liste
        </Button>
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    padding: '32px',
    background: '#fff',
    borderRadius: '8px',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    maxWidth: '800px',
    margin: '0 auto',
  },
  buttons: {
    marginTop: '24px',
    display: 'flex',
    gap: '16px',
  },
  button: {
    textTransform: 'none',
  },
};

export default CandidateDetails;
