// src/components/StatCard.js
import React from 'react';
import { FaUsers, FaClipboardList, FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

const StatCard = ({ title, value, icon, color }) => {
  const IconComponent = icon;

  return (
    <div style={{ ...styles.card, borderTop: `5px solid ${color}` }}>
      <div style={styles.icon}>
        <IconComponent size={30} color={color} />
      </div>
      <div style={styles.content}>
        <h3 style={styles.title}>{title}</h3>
        <p style={styles.value}>{value}</p>
      </div>
    </div>
  );
};

const styles = {
  card: {
    display: 'flex',
    alignItems: 'center',
    padding: '16px',
    background: '#fff',
    borderRadius: '8px',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    marginBottom: '16px',
  },
  icon: {
    marginRight: '16px',
  },
  content: {
    flex: 1,
  },
  title: {
    margin: 0,
    fontSize: '18px',
    color: '#555',
  },
  value: {
    margin: 0,
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#000',
  },
};

export default StatCard;
