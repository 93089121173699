// src/Home.js
import React, { useEffect, useState } from 'react';
import { signOut } from 'firebase/auth';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';
import { db, auth } from './firebase'; // Importer 'auth' correctement
import { collection, getDocs } from 'firebase/firestore';
import StatCard from './components/StatCard';
import RecentActivities from './components/RecentActivities';
import OverviewChart from './components/OverviewChart';
import AuthStatus from './components/AuthStatus'; // Assurez-vous que ce composant existe
import {
  FaUsers,
  FaClipboardList,
  FaCheckCircle,
  FaTimesCircle,
  FaChalkboardTeacher, // Icône pour les formations
  FaCogs // Icône pour les paramètres
} from 'react-icons/fa';
import {
  Button,
  ButtonGroup,
  Typography,
  Box,
  Grid
} from '@mui/material'; // Importer les composants nécessaires de Material-UI

const Home = () => {
  const navigate = useNavigate();
  const { currentUser, loading } = useAuth();
  const [stats, setStats] = useState({
    totalCandidates: 0,
    pending: 0,
    accepted: 0,
    rejected: 0,
  });

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const candidatesSnapshot = await getDocs(collection(db, 'Candidates'));
        let total = 0;
        let pending = 0;
        let accepted = 0;
        let rejected = 0;

        candidatesSnapshot.forEach(doc => {
          total += 1;
          const status = doc.data().status;
          if (status === 'en attente') pending += 1;
          if (status === 'accepté') accepted += 1;
          if (status === 'rejeté') rejected += 1;
        });

        setStats({
          totalCandidates: total,
          pending,
          accepted,
          rejected,
        });
      } catch (error) {
        console.error("Erreur lors de la récupération des statistiques: ", error);
      }
    };

    fetchStats();
  }, []);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error("Erreur lors de la déconnexion: ", error);
    }
  };

  if (loading) {
    return <div style={styles.loading}>Chargement...</div>;
  }

  return (
    <div style={styles.container}>
      <header style={styles.header}>
        <Typography variant="h4">Dashboard Organisme de Formation</Typography>
        <Box style={styles.userSection}>
          <AuthStatus />
          <Button
            variant="contained"
            color="secondary"
            onClick={handleLogout}
            style={styles.logoutButton}
          >
            Se déconnecter
          </Button>
        </Box>
      </header>

      <section style={styles.statsSection}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <StatCard
              title="Total Candidats"
              value={stats.totalCandidates}
              icon={FaUsers}
              color="#0088FE"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatCard
              title="En Attente"
              value={stats.pending}
              icon={FaClipboardList}
              color="#00C49F"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatCard
              title="Acceptés"
              value={stats.accepted}
              icon={FaCheckCircle}
              color="#FFBB28"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatCard
              title="Rejetés"
              value={stats.rejected}
              icon={FaTimesCircle}
              color="#FF8042"
            />
          </Grid>
        </Grid>
      </section>

      {/* Section Gestion des Candidats */}
      <section style={styles.actionSection}>
        <Typography variant="h5" gutterBottom>Gestion des Candidats</Typography>
        <ButtonGroup variant="contained" aria-label="gestion des candidats">
          <Button
            component={Link}
            to="/candidates/add"
            color="primary"
            style={styles.button}
          >
            Ajouter un Candidat
          </Button>
          <Button
            component={Link}
            to="/candidates"
            color="secondary"
            style={styles.button}
          >
            Voir les Candidats
          </Button>
        </ButtonGroup>
      </section>

      {/* Section Gestion des Formations */}
      <section style={styles.actionSection}>
        <Typography variant="h5" gutterBottom>Gestion des Formations</Typography>
        <ButtonGroup variant="contained" aria-label="gestion des formations">
          <Button
            component={Link}
            to="/formations/add"
            color="primary"
            style={styles.button}
          >
            Ajouter une Formation
          </Button>
          <Button
            component={Link}
            to="/formations"
            color="secondary"
            style={styles.button}
          >
            Voir les Formations
          </Button>
        </ButtonGroup>
      </section>

      {/* Section Configuration des Voeux */}
      <section style={styles.actionSection}>
        <Typography variant="h5" gutterBottom>Configuration des Voeux</Typography>
        <Button
          component={Link}
          to="/settings/voeux"
          variant="contained"
          color="info"
          startIcon={<FaCogs />}
          style={styles.button}
        >
          Configurer le Nombre de Voeux par Candidat
        </Button>
      </section>

      <section style={styles.overviewSection}>
        <Typography variant="h5" gutterBottom>Vue d'ensemble des Candidatures</Typography>
        <OverviewChart />
      </section>

      <section style={styles.activitiesSection}>
        <Typography variant="h5" gutterBottom>Activités Récentes</Typography>
        <RecentActivities />
      </section>
    </div>
  );
};

const styles = {
  container: {
    padding: '32px',
    background: '#f0f2f5',
    minHeight: '100vh',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '32px',
  },
  userSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  logoutButton: {
    marginTop: '8px',
  },
  statsSection: {
    marginBottom: '32px',
  },
  actionSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '32px',
  },
  button: {
    margin: '0 8px',
  },
  overviewSection: {
    marginBottom: '32px',
  },
  activitiesSection: {
    marginBottom: '32px',
  },
  loading: {
    textAlign: 'center',
    marginTop: '100px',
    fontSize: '24px',
  },
};

export default Home;
