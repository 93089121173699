// src/components/RecentActivities.js
import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { collection, query, orderBy, limit, onSnapshot } from 'firebase/firestore';
import { FaUserPlus, FaEdit, FaTrash } from 'react-icons/fa';

const RecentActivities = () => {
  const [activities, setActivities] = useState([]);

  useEffect(() => {
    const q = query(
      collection(db, 'Activities'),
      orderBy('timestamp', 'desc'),
      limit(5)
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const activitiesData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setActivities(activitiesData);
    });

    return () => unsubscribe();
  }, []);

  return (
    <div style={styles.container}>
      <h3>Activités Récentes</h3>
      <ul style={styles.list}>
        {activities.map(activity => (
          <li key={activity.id} style={styles.listItem}>
            {activity.type === 'add' && <FaUserPlus color="#4caf50" />}
            {activity.type === 'edit' && <FaEdit color="#ff9800" />}
            {activity.type === 'delete' && <FaTrash color="#f44336" />}
            <span style={styles.description}>{activity.description}</span>
            <span style={styles.timestamp}>{new Date(activity.timestamp.seconds * 1000).toLocaleString()}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

const styles = {
  container: {
    padding: '16px',
    background: '#fff',
    borderRadius: '8px',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    marginTop: '16px',
  },
  list: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '12px',
  },
  description: {
    marginLeft: '8px',
    flex: 1,
    color: '#555',
  },
  timestamp: {
    fontSize: '12px',
    color: '#999',
  },
};

export default RecentActivities;
