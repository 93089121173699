import React, { useEffect, useState } from 'react';
import { auth, db } from './firebase';
import { signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';

const CandidateDashboard = () => {
  const { currentUser, loading } = useAuth();
  const [candidateData, setCandidateData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCandidateData = async () => {
      if (loading) return;

      if (!currentUser) {
        console.log('Utilisateur non authentifié');
        navigate('/');
        return;
      }

      try {
        const candidateDocRef = doc(db, 'Candidates', currentUser.uid);
        const candidateDocSnap = await getDoc(candidateDocRef);

        if (candidateDocSnap.exists()) {
          setCandidateData(candidateDocSnap.data());
        } else {
          console.log('Aucune donnée trouvée pour ce candidat.');
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des données du candidat :', error);
      }
    };

    fetchCandidateData();
  }, [currentUser, loading, navigate]);

  const handleLogout = async () => {
    await signOut(auth);
    navigate('/');
  };

  const getStatusStyle = (status) => {
    switch (status.toLowerCase()) {
      case 'en attente':
        return styles.statusPending;
      case 'rejeté':
        return styles.statusRejected;
      case 'accepté':
        return styles.statusAccepted;
      default:
        return styles.statusDefault;
    }
  };

  if (loading) {
    return <div style={styles.loading}>Chargement...</div>;
  }

  return (
    <div style={styles.container}>
      <header style={styles.header}>
        <h1 style={styles.title}>
          Bienvenue {candidateData ? `${candidateData.firstName} ${candidateData.lastName}` : 'Candidat'}
        </h1>
        <button onClick={handleLogout} style={styles.logoutButton}>
          Déconnexion
        </button>
      </header>

      {candidateData ? (
        <>
          {/* Informations personnelles */}
          <div style={styles.infoContainer}>
            <h2 style={styles.subTitle}>Mes Informations</h2>
            <div style={styles.infoCard}>
              <p><strong>Prénom :</strong> {candidateData.firstName}</p>
              <p><strong>Nom :</strong> {candidateData.lastName}</p>
              <p><strong>Email :</strong> {candidateData.email}</p>
              <p>
                <strong>Statut de la candidature :</strong>{' '}
                <span style={getStatusStyle(candidateData.status)}>
                  {candidateData.status}
                </span>
              </p>
            </div>
          </div>
          {/* Historique des actions */}
          <div style={styles.timelineContainer}>
            <h3>Historique de ma candidature</h3>
            <ul style={styles.timeline}>
              <li>Inscription complétée - 01/11/2024</li>
              <li>Entretien programmé - 15/11/2024</li>
              <li>En attente de validation - 20/11/2024</li>
            </ul>
          </div>

          {/* Rappels */}
          <div style={styles.reminders}>
            <h3>Rappels importants</h3>
            <p>📅 Soumettez vos documents manquants avant le <strong>30 novembre 2024</strong>.</p>
          </div>

          {/* Section FAQ */}
          <div style={styles.faq}>
            <h3>FAQ</h3>
            <p><strong>Q : Comment puis-je modifier mes informations ?</strong></p>
            <p>
              R : Veuillez contacter le service administratif via{' '}
              <a href="mailto:admin@example.com" style={styles.link}>
                admin@example.com
              </a>.
            </p>
            <p><strong>Q : Quels documents dois-je fournir ?</strong></p>
            <p>R : Les pièces demandées incluent une pièce d'identité, un CV et une lettre de motivation.</p>
          </div>
        </>
      ) : (
        <p style={styles.loading}>Chargement des données...</p>
      )}
    </div>
  );
};

const styles = {
  container: {
    fontFamily: '"Segoe UI", Tahoma, Geneva, Verdana, sans-serif',
    margin: '0 auto',
    padding: '20px',
    maxWidth: '800px',
    color: '#333',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#007BFF',
  },
  logoutButton: {
    backgroundColor: '#dc3545',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    padding: '10px 15px',
    cursor: 'pointer',
    fontSize: '16px',
    transition: 'background-color 0.3s ease',
  },
  profileContainer: {
    textAlign: 'center',
    marginBottom: '20px',
  },
  profileImage: {
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    objectFit: 'cover',
    border: '2px solid #007BFF',
  },
  infoContainer: {
    backgroundColor: '#f8f9fa',
    borderRadius: '8px',
    padding: '20px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },
  subTitle: {
    fontSize: '20px',
    fontWeight: '600',
    marginBottom: '15px',
  },
  infoCard: {
    lineHeight: '1.6',
    color: '#555',
  },
  progressBarContainer: {
    backgroundColor: '#e9ecef',
    borderRadius: '20px',
    height: '25px',
    marginTop: '20px',
    overflow: 'hidden',
  },
  progressBar: {
    backgroundColor: '#28A745',
    height: '100%',
    borderRadius: '20px',
    textAlign: 'center',
    lineHeight: '25px',
    color: 'white',
    fontWeight: 'bold',
  },
  timelineContainer: {
    marginTop: '20px',
    padding: '15px',
    backgroundColor: '#f1f1f1',
    borderRadius: '8px',
  },
  timeline: {
    listStyleType: 'none',
    padding: '0',
    lineHeight: '1.8',
    color: '#333',
  },
  reminders: {
    backgroundColor: '#FFF3CD',
    border: '1px solid #FFEEBA',
    borderRadius: '8px',
    padding: '15px',
    marginTop: '20px',
    color: '#856404',
  },
  faq: {
    marginTop: '20px',
    padding: '15px',
    backgroundColor: '#ffffff',
    border: '1px solid #ddd',
    borderRadius: '8px',
  },
  link: {
    color: '#007BFF',
    textDecoration: 'none',
  },
  statusPending: {
    backgroundColor: '#FFC107',
    color: '#fff',
    padding: '5px 10px',
    borderRadius: '5px',
    display: 'inline-block',
    fontWeight: 'bold',
  },
  statusRejected: {
    backgroundColor: '#DC3545',
    color: '#fff',
    padding: '5px 10px',
    borderRadius: '5px',
    display: 'inline-block',
    fontWeight: 'bold',
  },
  statusAccepted: {
    backgroundColor: '#28A745',
    color: '#fff',
    padding: '5px 10px',
    borderRadius: '5px',
    display: 'inline-block',
    fontWeight: 'bold',
  },
  loading: {
    textAlign: 'center',
    fontSize: '18px',
    color: '#007BFF',
  },
};

export default CandidateDashboard;
