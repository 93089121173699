// src/components/Dashboard.js
import React from 'react';
import { Link } from 'react-router-dom';

const Dashboard = () => {
  return (
    <div style={styles.container}>
      <h1>Dashboard Organisme de Formation</h1>
      <nav style={styles.nav}>
        <Link to="/candidates" style={styles.link}>Gestion des Candidatures</Link>
        <Link to="/courses" style={styles.link}>Gestion des Cours</Link>
        {/* Ajoutez d'autres liens si nécessaire */}
      </nav>
    </div>
  );
};

const styles = {
  container: {
    padding: '32px',
    textAlign: 'center',
  },
  nav: {
    marginTop: '24px',
    display: 'flex',
    justifyContent: 'center',
    gap: '16px',
  },
  link: {
    padding: '12px 24px',
    background: '#039be5',
    color: '#fff',
    borderRadius: '4px',
    textDecoration: 'none',
  },
};

export default Dashboard;
