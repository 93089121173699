// src/Auth.js
import React, { useState } from 'react';
import { auth, db } from './firebase';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

const Auth = () => {
  const [isRegistering, setIsRegistering] = useState(false); // État pour basculer entre connexion et inscription
  const [userType, setUserType] = useState('organism'); // 'organism' ou 'candidate'
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [organizationName, setOrganizationName] = useState(''); // Nom de l'organisme pour les organismes
  const [candidateName, setCandidateName] = useState(''); // Nom du candidat pour les candidats
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // Fonction pour gérer la soumission du formulaire de connexion
  const handleLogin = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      await signInWithEmailAndPassword(auth, email, password);

      // Récupérer le rôle de l'utilisateur depuis Firestore
      const user = auth.currentUser;
      let role = '';

      // Vérifier dans la collection 'Users' (organismes et candidats)
      const userDoc = await getDoc(doc(db, 'Users', user.uid));
      if (userDoc.exists()) {
        role = userDoc.data().role;
      } else {
        setError("Impossible de trouver les informations de l'utilisateur.");
        return;
      }

      // Rediriger en fonction du rôle
      if (role === 'organism') {
        navigate('/home'); // Tableau de bord des organismes
      } else if (role === 'candidate') {
        navigate('/candidate-dashboard'); // Tableau de bord des candidats
      } else {
        setError("Impossible de déterminer le rôle de l'utilisateur.");
      }
    } catch (err) {
      setError(err.message);
    }
  };

  // Fonction pour gérer la soumission du formulaire d'inscription
  const handleRegister = async (e) => {
    e.preventDefault();
    setError(null);

    // Vérifier que les mots de passe correspondent
    if (password !== confirmPassword) {
      setError("Les mots de passe ne correspondent pas.");
      return;
    }

    // Vérifier les champs requis en fonction du type d'utilisateur
    if (userType === 'organism' && !organizationName.trim()) {
      setError("Le nom de l'organisme est requis.");
      return;
    }
    if (userType === 'candidate' && !candidateName.trim()) {
      setError("Le nom du candidat est requis.");
      return;
    }

    try {
      // Créer l'utilisateur avec Firebase Auth
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Préparer les données à enregistrer dans Firestore
      const userData = {
        email: user.email,
        role: userType,
        createdAt: new Date(),
      };

      if (userType === 'organism') {
        userData.name = organizationName;
      } else if (userType === 'candidate') {
        userData.name = candidateName;
      }

      // Enregistrer dans la collection "Users"
      await setDoc(doc(db, 'Users', user.uid), userData);

      // Rediriger en fonction du rôle
      if (userType === 'organism') {
        navigate('/home');
      } else if (userType === 'candidate') {
        navigate('/candidate-dashboard');
      }
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div style={styles.container}>
      {isRegistering ? (
        // Formulaire d'inscription
        <div style={styles.formContainer}>
          <h2>Inscription</h2>
          {error && <p style={styles.error}>{error}</p>}
          <form onSubmit={handleRegister} style={styles.form}>
            {/* Sélection du type d'utilisateur */}
            <div style={styles.inputGroup}>
              <label>Type d'utilisateur :</label>
              <div>
                <label>
                  <input
                    type="radio"
                    value="organism"
                    checked={userType === 'organism'}
                    onChange={(e) => setUserType(e.target.value)}
                  />
                  Organisme
                </label>
                <label style={{ marginLeft: '16px' }}>
                  <input
                    type="radio"
                    value="candidate"
                    checked={userType === 'candidate'}
                    onChange={(e) => setUserType(e.target.value)}
                  />
                  Candidat
                </label>
              </div>
            </div>
            {/* Champ pour le nom de l'organisme (organismes) */}
            {userType === 'organism' && (
              <div style={styles.inputGroup}>
                <label>Nom de l'Organisme :</label>
                <input
                  type="text"
                  value={organizationName}
                  onChange={(e) => setOrganizationName(e.target.value)}
                  required={userType === 'organism'}
                  style={styles.input}
                  placeholder="Entrez le nom de l'organisme"
                />
              </div>
            )}
            {/* Champ pour le nom du candidat (candidats) */}
            {userType === 'candidate' && (
              <div style={styles.inputGroup}>
                <label>Nom :</label>
                <input
                  type="text"
                  value={candidateName}
                  onChange={(e) => setCandidateName(e.target.value)}
                  required={userType === 'candidate'}
                  style={styles.input}
                  placeholder="Entrez votre nom"
                />
              </div>
            )}
            <div style={styles.inputGroup}>
              <label>Email :</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                style={styles.input}
                placeholder="Entrez votre email"
              />
            </div>
            <div style={styles.inputGroup}>
              <label>Mot de Passe :</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                style={styles.input}
                placeholder="Entrez votre mot de passe"
              />
            </div>
            <div style={styles.inputGroup}>
              <label>Confirmer le Mot de Passe :</label>
              <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                style={styles.input}
                placeholder="Confirmez votre mot de passe"
              />
            </div>
            <button type="submit" style={styles.button}>S'inscrire</button>
          </form>
          <p style={styles.toggleText}>
            Vous avez déjà un compte ?{' '}
            <span style={styles.toggleLink} onClick={() => setIsRegistering(false)}>
              Connectez-vous
            </span>
          </p>
        </div>
      ) : (
        // Formulaire de connexion
        <div style={styles.formContainer}>
          <h2>Connexion</h2>
          {error && <p style={styles.error}>{error}</p>}
          <form onSubmit={handleLogin} style={styles.form}>
            <div style={styles.inputGroup}>
              <label>Email :</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                style={styles.input}
                placeholder="Entrez votre email"
              />
            </div>
            <div style={styles.inputGroup}>
              <label>Mot de Passe :</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                style={styles.input}
                placeholder="Entrez votre mot de passe"
              />
            </div>
            <button type="submit" style={styles.button}>Se Connecter</button>
          </form>
          <p style={styles.toggleText}>
            Vous n'avez pas de compte ?{' '}
            <span style={styles.toggleLink} onClick={() => setIsRegistering(true)}>
              Inscrivez-vous
            </span>
          </p>
        </div>
      )}
    </div>
  );
};

// Styles en ligne pour simplifier l'exemple
const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    background: '#f5f5f5',
  },
  formContainer: {
    background: '#fff',
    padding: '32px',
    borderRadius: '8px',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12)',
    width: '400px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  inputGroup: {
    marginBottom: '16px',
  },
  input: {
    width: '100%',
    padding: '8px',
    marginTop: '4px',
    boxSizing: 'border-box',
    borderRadius: '4px',
    border: '1px solid #ccc',
  },
  button: {
    padding: '12px',
    background: '#039be5',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px',
  },
  error: {
    color: 'red',
    marginBottom: '16px',
    textAlign: 'center',
  },
  toggleText: {
    marginTop: '16px',
    textAlign: 'center',
  },
  toggleLink: {
    color: '#039be5',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
};

export default Auth;
