// src/components/EditCandidate.js
import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { doc, getDoc, updateDoc, Timestamp, getDocs, collection } from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';
import { addActivity } from '../utils/activities'; // Assurez-vous que ce chemin est correct
import { TextField, Button, Typography, Box, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, OutlinedInput, FormControlLabel } from '@mui/material';

const EditCandidate = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [candidate, setCandidate] = useState({
    firstName: '',
    lastName: '',
    email: '',
    status: 'en attente',
    voeux: [],
    espaceCandidatActive: false, // Nouvelle case à cocher
  });
  const [formations, setFormations] = useState([]);
  const [maxVoeux, setMaxVoeux] = useState(1);
  const [password, setPassword] = useState(''); // Nouveau champ mot de passe
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchCandidate = async () => {
      try {
        const candidateDoc = await getDoc(doc(db, 'Candidates', id));
        if (candidateDoc.exists()) {
          const data = candidateDoc.data();
          setCandidate({
            id: candidateDoc.id,
            ...data,
            voeux: data.voeux || [],
            espaceCandidatActive: data.espaceCandidatActive || false,
          });
        } else {
          console.log("Aucun candidat trouvé !");
        }
      } catch (err) {
        console.error("Erreur lors de la récupération du candidat: ", err);
      }
    };

    const fetchFormations = async () => {
      try {
        const formationsSnapshot = await getDocs(collection(db, 'Formations'));
        const formationsData = formationsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setFormations(formationsData);
      } catch (err) {
        console.error("Erreur lors de la récupération des formations: ", err);
      }
    };

    const fetchSettings = async () => {
      try {
        const settingsSnapshot = await getDocs(collection(db, 'Settings'));
        settingsSnapshot.forEach(doc => {
          if (doc.id === 'voeux') {
            setMaxVoeux(doc.data().maxVoeux || 1);
          }
        });
      } catch (err) {
        console.error("Erreur lors de la récupération des settings: ", err);
      }
    };

    fetchCandidate();
    fetchFormations();
    fetchSettings();
  }, [id]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setCandidate(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleVoeuxChange = (event) => {
    const { target: { value } } = event;
    setCandidate(prev => ({
      ...prev,
      voeux: typeof value === 'string' ? value.split(',') : value,
    }));
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    // Validation de base
    if (!candidate.firstName || !candidate.lastName || !candidate.email) {
      setError('Veuillez remplir tous les champs obligatoires.');
      return;
    }

    if (candidate.voeux.length > maxVoeux) {
      setError(`Vous ne pouvez sélectionner que ${maxVoeux} voeux.`);
      return;
    }

    try {
      const candidateRef = doc(db, 'Candidates', id);
      const updatedData = {
        firstName: candidate.firstName,
        lastName: candidate.lastName,
        email: candidate.email,
        status: candidate.status,
        voeux: candidate.voeux,
        espaceCandidatActive: candidate.espaceCandidatActive,
        updatedAt: Timestamp.fromDate(new Date()),
      };

      // Si un nouveau mot de passe est fourni, ajoutez-le aux données
      if (password.trim() !== '') {
        updatedData.password = password;
      }

      await updateDoc(candidateRef, updatedData);

      // Ajouter une activité de modification de candidat
      await addActivity(`Modification du candidat ${candidate.firstName} ${candidate.lastName}`);

      navigate('/candidates');
    } catch (err) {
      console.error("Erreur lors de la modification du candidat: ", err);
      setError('Erreur lors de la modification du candidat. Veuillez réessayer.');
    }
  };

  return (
    <Box sx={styles.container}>
      <Typography variant="h5" gutterBottom>Modifier le Candidat</Typography>
      {error && <Typography variant="body1" color="error">{error}</Typography>}
      <form onSubmit={handleSubmit} style={styles.form}>
        <TextField
          label="Prénom"
          name="firstName"
          value={candidate.firstName}
          onChange={handleChange}
          required
          fullWidth
          margin="normal"
        />
        <TextField
          label="Nom"
          name="lastName"
          value={candidate.lastName}
          onChange={handleChange}
          required
          fullWidth
          margin="normal"
        />
        <TextField
          label="Email"
          name="email"
          type="email"
          value={candidate.email}
          onChange={handleChange}
          required
          fullWidth
          margin="normal"
        />
        {/* Section Voeux */}
        <FormControl fullWidth margin="normal">
          <InputLabel id="voeux-label">Voeux (Formations)</InputLabel>
          <Select
            labelId="voeux-label"
            multiple
            value={candidate.voeux}
            onChange={handleVoeuxChange}
            input={<OutlinedInput label="Voeux (Formations)" />}
            renderValue={(selected) => selected.map(id => {
              const formation = formations.find(f => f.id === id);
              return formation ? formation.title : id;
            }).join(', ')}
          >
            {formations.map((formation) => (
              <MenuItem key={formation.id} value={formation.id}>
                <Checkbox checked={ (candidate.voeux || []).indexOf(formation.id) > -1} />
                <ListItemText primary={formation.title} />
              </MenuItem>
            ))}
          </Select>
          <Typography variant="caption">Sélectionnez jusqu'à {maxVoeux} voeux.</Typography>
        </FormControl>
        {/* Case à cocher Espace Candidat Activé */}
        <FormControlLabel
          control={
            <Checkbox
              checked={candidate.espaceCandidatActive}
              onChange={handleChange}
              name="espaceCandidatActive"
              color="primary"
            />
          }
          label="Espace Candidat Activé"
        />
        {/* Champ Mot de Passe (Optionnel) */}
        {candidate.espaceCandidatActive && (
          <TextField
            label="Mot de Passe"
            name="password"
            type="password"
            value={password}
            onChange={handlePasswordChange}
            fullWidth
            margin="normal"
            helperText="Laissez vide si vous ne souhaitez pas changer le mot de passe."
          />
        )}
        <Button type="submit" variant="contained" color="primary" sx={styles.button}>
          Modifier
        </Button>
      </form>
    </Box>
  );
};

const styles = {
  container: {
    padding: '32px',
    background: '#fff',
    borderRadius: '8px',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    maxWidth: '600px',
    margin: '0 auto',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    marginTop: '16px',
  },
};

export default EditCandidate;
