// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Auth from './Auth';
import Home from './Home';
import Dashboard from './components/Dashboard';
import CandidatesList from './components/CandidatesList';
import AddCandidate from './components/AddCandidate';
import EditCandidate from './components/EditCandidate';
import CandidateDetails from './components/CandidateDetails';
import CoursesList from './components/CoursesList';
import PrivateRoute from './PrivateRoute';
import { AuthProvider } from './contexts/AuthContext';
import FormationsList from './components/FormationsList';
import AddFormation from './components/AddFormation';
import ConfigureVoeux from './components/ConfigureVoeux';
import CandidateDashboard from './CandidateDashboard'; // Import du tableau de bord candidat

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          {/* Route publique pour l'authentification */}
          <Route path="/" element={<Auth />} />

          {/* Route protégée pour le tableau de bord du candidat */}
          <Route
            path="/candidate-dashboard"
            element={
              <PrivateRoute role="candidate">
                <CandidateDashboard />
              </PrivateRoute>
            }
          />

          {/* Route protégée pour le tableau de bord de l'organisme */}
          <Route
            path="/home"
            element={
              <PrivateRoute role="organism">
                <Home />
              </PrivateRoute>
            }
          />

          {/* Autres routes protégées pour les organismes */}
          <Route
            path="/dashboard"
            element={
              <PrivateRoute role="organism">
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/candidates"
            element={
              <PrivateRoute role="organism">
                <CandidatesList />
              </PrivateRoute>
            }
          />
          <Route
            path="/candidates/add"
            element={
              <PrivateRoute role="organism">
                <AddCandidate />
              </PrivateRoute>
            }
          />
          <Route
            path="/candidates/edit/:id"
            element={
              <PrivateRoute role="organism">
                <EditCandidate />
              </PrivateRoute>
            }
          />
          <Route
            path="/candidates/:id"
            element={
              <PrivateRoute role="organism">
                <CandidateDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/courses"
            element={
              <PrivateRoute role="organism">
                <CoursesList />
              </PrivateRoute>
            }
          />
          {/* Routes pour les formations */}
          <Route
            path="/formations"
            element={
              <PrivateRoute role="organism">
                <FormationsList />
              </PrivateRoute>
            }
          />
          <Route
            path="/formations/add"
            element={
              <PrivateRoute role="organism">
                <AddFormation />
              </PrivateRoute>
            }
          />
          {/* Route pour la configuration des voeux */}
          <Route
            path="/settings/voeux"
            element={
              <PrivateRoute role="organism">
                <ConfigureVoeux />
              </PrivateRoute>
            }
          />
          {/* Redirection pour les routes non définies */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;
