// src/PrivateRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';

const PrivateRoute = ({ children, role }) => {
  const { currentUser, userRole, loading } = useAuth();

  if (loading) {
    // Vous pouvez afficher un indicateur de chargement ici
    return <div>Chargement...</div>;
  }

  if (!currentUser) {
    // Si l'utilisateur n'est pas connecté, redirige vers la page d'authentification
    return <Navigate to="/" />;
  }

  if (role && userRole !== role) {
    // Si l'utilisateur n'a pas le rôle requis, redirige en fonction de son rôle
    if (userRole === 'organism') {
      return <Navigate to="/home" />;
    } else if (userRole === 'candidate') {
      return <Navigate to="/candidate-dashboard" />;
    } else {
      // Si le rôle est inconnu, redirige vers la page d'authentification
      return <Navigate to="/" />;
    }
  }

  // Si tout est en ordre, rend le composant enfant
  return children;
};

export default PrivateRoute;
