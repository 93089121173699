// src/components/CandidatesList.js
import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { collection, getDocs, updateDoc, doc, Timestamp } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { Button, Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, FormControl, Select, MenuItem, InputLabel } from '@mui/material';
import { FaTrash, FaEdit, FaEye, FaPlus, FaCheck, FaTimes } from 'react-icons/fa';
import { addActivity } from '../utils/activities'; // Assurez-vous que ce chemin est correct

const CandidatesList = () => {
  const [candidates, setCandidates] = useState([]);
  const [formations, setFormations] = useState([]);
  const [maxVoeux, setMaxVoeux] = useState(1);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchCandidates = async () => {
      try {
        const candidatesSnapshot = await getDocs(collection(db, 'Candidates'));
        const candidatesData = candidatesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setCandidates(candidatesData);
      } catch (err) {
        console.error("Erreur lors de la récupération des candidats: ", err);
      }
    };

    const fetchFormations = async () => {
      try {
        const formationsSnapshot = await getDocs(collection(db, 'Formations'));
        const formationsData = formationsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setFormations(formationsData);
      } catch (err) {
        console.error("Erreur lors de la récupération des formations: ", err);
      }
    };

    const fetchSettings = async () => {
      try {
        const settingsSnapshot = await getDocs(collection(db, 'Settings'));
        settingsSnapshot.forEach(doc => {
          if (doc.id === 'voeux') {
            setMaxVoeux(doc.data().maxVoeux || 1);
          }
        });
      } catch (err) {
        console.error("Erreur lors de la récupération des settings: ", err);
      }
    };

    fetchCandidates();
    fetchFormations();
    fetchSettings();
  }, []);

  const handleStatusChange = async (candidateId, newStatus) => {
    try {
      const candidateRef = doc(db, 'Candidates', candidateId);
      await updateDoc(candidateRef, { status: newStatus, updatedAt: Timestamp.fromDate(new Date()) });

      // Ajouter une activité de changement de statut
      const candidate = candidates.find(c => c.id === candidateId);
      if (candidate) {
        await addActivity(`Changement de statut du candidat ${candidate.firstName} ${candidate.lastName} à "${newStatus}"`);
      }

      setCandidates(candidates.map(c => c.id === candidateId ? { ...c, status: newStatus } : c));
    } catch (err) {
      console.error("Erreur lors du changement de statut: ", err);
      setError('Erreur lors du changement de statut. Veuillez réessayer.');
    }
  };

  return (
    <Box sx={styles.container}>
      <Typography variant="h5" gutterBottom>Liste des Candidats</Typography>
      <Button
        component={Link}
        to="/candidates/add"
        variant="contained"
        color="primary"
        startIcon={<FaPlus />}
        sx={styles.addButton}
      >
        Ajouter un Candidat
      </Button>
      {error && <Typography variant="body1" color="error">{error}</Typography>}
      <TableContainer component={Paper} sx={styles.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Prénom</TableCell>
              <TableCell>Nom</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Voeux</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {candidates.map((candidate) => (
              <TableRow key={candidate.id}>
                <TableCell>{candidate.firstName}</TableCell>
                <TableCell>{candidate.lastName}</TableCell>
                <TableCell>{candidate.email}</TableCell>
                <TableCell>
                  {candidate.voeux.map(voeuId => {
                    const formation = formations.find(f => f.id === voeuId);
                    return formation ? formation.title : voeuId;
                  }).join(', ')}
                </TableCell>
                <TableCell>
                  <FormControl variant="standard" sx={{ minWidth: 120 }}>
                    <InputLabel>Status</InputLabel>
                    <Select
                      value={candidate.status}
                      onChange={(e) => handleStatusChange(candidate.id, e.target.value)}
                      label="Status"
                    >
                      <MenuItem value="en attente">En Attente</MenuItem>
                      <MenuItem value="accepté">Accepté</MenuItem>
                      <MenuItem value="rejeté">Rejeté</MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell>
                  <IconButton component={Link} to={`/candidates/${candidate.id}`} color="primary">
                    <FaEye />
                  </IconButton>
                  <IconButton component={Link} to={`/candidates/edit/${candidate.id}`} color="secondary">
                    <FaEdit />
                  </IconButton>
                  {/* Vous pouvez ajouter des boutons supplémentaires ici si nécessaire */}
                </TableCell>
              </TableRow>
            ))}
            {candidates.length === 0 && (
              <TableRow>
                <TableCell colSpan={6} align="center">Aucun candidat disponible.</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const styles = {
  container: {
    padding: '32px',
    background: '#fff',
    borderRadius: '8px',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
  },
  addButton: {
    marginBottom: '16px',
  },
  tableContainer: {
    marginTop: '16px',
  },
};

export default CandidatesList;
