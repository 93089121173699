// src/components/ConfigureVoeux.js
import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { TextField, Button, Typography, Box } from '@mui/material';

const ConfigureVoeux = () => {
  const [maxVoeux, setMaxVoeux] = useState(1);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchMaxVoeux = async () => {
      const docRef = doc(db, 'Settings', 'voeux');
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setMaxVoeux(docSnap.data().maxVoeux);
      }
    };
    fetchMaxVoeux();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await setDoc(doc(db, 'Settings', 'voeux'), { maxVoeux });
      setMessage('Nombre de voeux mis à jour avec succès.');
    } catch (error) {
      console.error("Erreur lors de la mise à jour des voeux: ", error);
      setMessage('Erreur lors de la mise à jour.');
    }
  };

  return (
    <Box sx={styles.container}>
      <Typography variant="h5" gutterBottom>Configurer le Nombre de Voeux par Candidat</Typography>
      <form onSubmit={handleSubmit} style={styles.form}>
        <TextField
          label="Nombre de Voeux"
          type="number"
          value={maxVoeux}
          onChange={(e) => setMaxVoeux(parseInt(e.target.value))}
          InputProps={{ inputProps: { min: 1 } }}
          required
          style={styles.input}
        />
        <Button type="submit" variant="contained" color="primary" style={styles.button}>
          Enregistrer
        </Button>
      </form>
      {message && <Typography variant="body1" color="success.main">{message}</Typography>}
    </Box>
  );
};

const styles = {
  container: {
    padding: '32px',
    background: '#fff',
    borderRadius: '8px',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    maxWidth: '400px',
    margin: '0 auto',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    marginBottom: '16px',
  },
  button: {
    alignSelf: 'flex-start',
  },
};

export default ConfigureVoeux;
