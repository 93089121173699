// src/components/AddFormation.js
import React, { useState } from 'react';
import { db } from '../firebase';
import { collection, addDoc, Timestamp } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { addActivity } from '../utils/activities'; // Fonction utilitaire pour ajouter une activité
import { TextField, Button, Typography, Box } from '@mui/material';

const AddFormation = () => {
  const [formation, setFormation] = useState({
    title: '',
    description: '',
    duration: '',
  });
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormation({ ...formation, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    // Validation de base
    if (!formation.title || !formation.description || !formation.duration) {
      setError('Veuillez remplir tous les champs.');
      return;
    }

    try {
      const docRef = await addDoc(collection(db, 'Formations'), {
        ...formation,
        createdAt: Timestamp.fromDate(new Date()),
        updatedAt: Timestamp.fromDate(new Date()),
      });

      // Ajouter une activité de création de formation
      await addActivity(`Ajout de la formation "${formation.title}"`);

      navigate('/formations');
    } catch (err) {
      console.error("Erreur lors de l'ajout de la formation: ", err);
      setError('Erreur lors de l\'ajout de la formation. Veuillez réessayer.');
    }
  };

  return (
    <Box sx={styles.container}>
      <Typography variant="h5" gutterBottom>Ajouter une Nouvelle Formation</Typography>
      {error && <Typography variant="body1" color="error">{error}</Typography>}
      <form onSubmit={handleSubmit} style={styles.form}>
        <TextField
          label="Titre de la Formation"
          name="title"
          value={formation.title}
          onChange={handleChange}
          required
          fullWidth
          margin="normal"
        />
        <TextField
          label="Description"
          name="description"
          value={formation.description}
          onChange={handleChange}
          required
          fullWidth
          multiline
          rows={4}
          margin="normal"
        />
        <TextField
          label="Durée (heures)"
          name="duration"
          type="number"
          value={formation.duration}
          onChange={handleChange}
          required
          fullWidth
          margin="normal"
        />
        <Button type="submit" variant="contained" color="primary" sx={styles.button}>
          Ajouter
        </Button>
      </form>
    </Box>
  );
};

const styles = {
  container: {
    padding: '32px',
    background: '#fff',
    borderRadius: '8px',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    maxWidth: '600px',
    margin: '0 auto',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    marginTop: '16px',
  },
};

export default AddFormation;
