// src/components/AuthStatus.js
import React from 'react';
import { useAuth } from '../contexts/AuthContext';

const AuthStatus = () => {
  const { currentUser, loading } = useAuth();

  if (loading) {
    return <p>Chargement de l'état d'authentification...</p>;
  }

  return (
    <div>
      {currentUser ? (
        <p>Utilisateur connecté : {currentUser.email}</p>
      ) : (
        <p>Aucun utilisateur connecté.</p>
      )}
    </div>
  );
};

export default AuthStatus;
