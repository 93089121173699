import React, { createContext, useState, useEffect, useContext } from 'react';
import { auth, db } from '../firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [userRole, setUserRole] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); // Nouveau : gestion des erreurs

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setLoading(true);
      setError(null);
      setCurrentUser(user);

      if (user) {
        try {
          // Récupérer le rôle de l'utilisateur depuis Firestore
          const userDoc = await getDoc(doc(db, 'Users', user.uid));
          if (userDoc.exists()) {
            setUserRole(userDoc.data().role);
          } else {
            setUserRole('');
            setError('Utilisateur non trouvé dans la collection "Users".');
          }
        } catch (err) {
          console.error('Erreur lors de la récupération des données utilisateur :', err);
          setError('Une erreur est survenue lors de la récupération des informations utilisateur.');
        }
      } else {
        setUserRole('');
      }

      setLoading(false);
    });

    return unsubscribe;
  }, []);

  // Fonction de déconnexion
  const logout = async () => {
    try {
      await signOut(auth);
      setCurrentUser(null);
      setUserRole('');
    } catch (err) {
      console.error('Erreur lors de la déconnexion :', err);
      setError('Une erreur est survenue lors de la déconnexion.');
    }
  };

  const value = {
    currentUser,
    userRole,
    loading,
    error, // Nouveau : exposition de l'erreur
    logout, // Nouveau : fonction de déconnexion
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
