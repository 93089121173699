// src/utils/activities.js

import { db } from '../firebase';
import { collection, addDoc, Timestamp } from 'firebase/firestore';

/**
 * Ajoute une activité dans la collection 'Activities' de Firestore.
 * @param {string} description - La description de l'activité.
 */
export const addActivity = async (description) => {
  try {
    await addDoc(collection(db, 'Activities'), {
      description,
      timestamp: Timestamp.now(),
      type: getActivityType(description),
    });
  } catch (error) {
    console.error("Erreur lors de l'ajout d'une activité: ", error);
  }
};

/**
 * Détermine le type d'activité basé sur la description.
 * @param {string} description - La description de l'activité.
 * @returns {string} Le type d'activité ('add', 'edit', 'delete', 'other').
 */
const getActivityType = (description) => {
  if (description.startsWith('Ajout')) return 'add';
  if (description.startsWith('Modification')) return 'edit';
  if (description.startsWith('Suppression')) return 'delete';
  return 'other';
};
