// src/components/FormationsList.js
import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { Button, Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { FaTrash, FaEdit, FaEye, FaPlus } from 'react-icons/fa';
import { addActivity } from '../utils/activities'; // Fonction utilitaire pour ajouter une activité

const FormationsList = () => {
  const [formations, setFormations] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [formationToDelete, setFormationToDelete] = useState(null);

  useEffect(() => {
    const fetchFormations = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'Formations'));
        const formationsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setFormations(formationsData);
      } catch (error) {
        console.error("Erreur lors de la récupération des formations: ", error);
      }
    };

    fetchFormations();
  }, []);

  const handleDelete = async () => {
    if (!formationToDelete) return;

    try {
      await deleteDoc(doc(db, 'Formations', formationToDelete.id));

      // Ajouter une activité de suppression de formation
      await addActivity(`Suppression de la formation "${formationToDelete.title}"`);

      setFormations(formations.filter(formation => formation.id !== formationToDelete.id));
      setOpenDialog(false);
      setFormationToDelete(null);
    } catch (error) {
      console.error("Erreur lors de la suppression de la formation: ", error);
    }
  };

  const confirmDelete = (formation) => {
    setFormationToDelete(formation);
    setOpenDialog(true);
  };

  const cancelDelete = () => {
    setOpenDialog(false);
    setFormationToDelete(null);
  };

  return (
    <Box sx={styles.container}>
      <Typography variant="h5" gutterBottom>Liste des Formations</Typography>
      <Button
        component={Link}
        to="/formations/add"
        variant="contained"
        color="primary"
        startIcon={<FaPlus />}
        sx={styles.addButton}
      >
        Ajouter une Formation
      </Button>
      <TableContainer component={Paper} sx={styles.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Titre</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Durée (heures)</TableCell>
              <TableCell>Date de Création</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {formations.map((formation) => (
              <TableRow key={formation.id}>
                <TableCell>{formation.title}</TableCell>
                <TableCell>{formation.description}</TableCell>
                <TableCell>{formation.duration}</TableCell>
                <TableCell>{formation.createdAt.toDate().toLocaleDateString()}</TableCell>
                <TableCell>
                  <IconButton component={Link} to={`/formations/${formation.id}`} color="primary">
                    <FaEye />
                  </IconButton>
                  <IconButton component={Link} to={`/formations/edit/${formation.id}`} color="secondary">
                    <FaEdit />
                  </IconButton>
                  <IconButton onClick={() => confirmDelete(formation)} color="error">
                    <FaTrash />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            {formations.length === 0 && (
              <TableRow>
                <TableCell colSpan={5} align="center">Aucune formation disponible.</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Dialog de confirmation de suppression */}
      <Dialog
        open={openDialog}
        onClose={cancelDelete}
      >
        <DialogTitle>Supprimer la Formation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Êtes-vous sûr de vouloir supprimer la formation "{formationToDelete?.title}" ? Cette action est irréversible.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelDelete} color="primary">
            Annuler
          </Button>
          <Button onClick={handleDelete} color="error">
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const styles = {
  container: {
    padding: '32px',
    background: '#fff',
    borderRadius: '8px',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
  },
  addButton: {
    marginBottom: '16px',
  },
  tableContainer: {
    marginTop: '16px',
  },
};

export default FormationsList;
