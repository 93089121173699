// src/components/OverviewChart.js
import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';

const COLORS = ['#0088FE', '#00C49F', '#FF8042'];

const OverviewChart = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchCandidates = async () => {
      const candidatesSnapshot = await getDocs(collection(db, 'Candidates'));
      const statusCount = {};

      candidatesSnapshot.forEach(doc => {
        const status = doc.data().status;
        statusCount[status] = (statusCount[status] || 0) + 1;
      });

      const chartData = Object.keys(statusCount).map(status => ({
        name: status,
        value: statusCount[status],
      }));

      setData(chartData);
    };

    fetchCandidates();
  }, []);

  return (
    <div style={styles.container}>
      <h3>Statut des Candidatures</h3>
      <PieChart width={400} height={300}>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
          outerRadius={100}
          fill="#8884d8"
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
        <Legend />
      </PieChart>
    </div>
  );
};

const styles = {
  container: {
    textAlign: 'center',
    marginTop: '16px',
    background: '#fff',
    padding: '16px',
    borderRadius: '8px',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
  },
};

export default OverviewChart;
