// src/components/AddCandidate.js
import React, { useState, useEffect } from 'react';
import { auth, db } from '../firebase';
import { collection, getDocs, doc, setDoc, Timestamp } from 'firebase/firestore';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { addActivity } from '../utils/activities';
import {
  TextField,
  Button,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  FormControlLabel,
} from '@mui/material';

const AddCandidate = () => {
  const navigate = useNavigate();
  const [candidate, setCandidate] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    status: 'en attente',
    voeux: [],
    espaceCandidatActive: false,
  });
  const [formations, setFormations] = useState([]);
  const [maxVoeux, setMaxVoeux] = useState(1);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchFormations = async () => {
      try {
        const formationsSnapshot = await getDocs(collection(db, 'Formations'));
        const formationsData = formationsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setFormations(formationsData);
      } catch (err) {
        console.error("Erreur lors de la récupération des formations: ", err);
      }
    };

    const fetchSettings = async () => {
      try {
        const settingsSnapshot = await getDocs(collection(db, 'Settings'));
        settingsSnapshot.forEach(doc => {
          if (doc.id === 'voeux') {
            setMaxVoeux(doc.data().maxVoeux || 1);
          }
        });
      } catch (err) {
        console.error("Erreur lors de la récupération des settings: ", err);
      }
    };

    fetchFormations();
    fetchSettings();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setCandidate(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleVoeuxChange = (event) => {
    const { target: { value } } = event;
    setCandidate(prev => ({
      ...prev,
      voeux: typeof value === 'string' ? value.split(',') : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    // Validation de base
    if (!candidate.firstName || !candidate.lastName || !candidate.email) {
      setError('Veuillez remplir tous les champs obligatoires.');
      return;
    }

    if (candidate.password !== candidate.confirmPassword) {
      setError('Les mots de passe ne correspondent pas.');
      return;
    }

    if (candidate.password.length < 6) {
      setError('Le mot de passe doit contenir au moins 6 caractères.');
      return;
    }

    if (candidate.voeux.length > maxVoeux) {
      setError(`Vous ne pouvez sélectionner que ${maxVoeux} vœux.`);
      return;
    }

    try {
      // Créer l'utilisateur dans Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(auth, candidate.email, candidate.password);
      const user = userCredential.user;

      // Ajouter le candidat à la collection "Candidates" avec son UID
      await setDoc(doc(db, 'Candidates', user.uid), {
        firstName: candidate.firstName,
        lastName: candidate.lastName,
        email: candidate.email,
        status: candidate.status,
        voeux: candidate.voeux,
        espaceCandidatActive: candidate.espaceCandidatActive,
        createdAt: Timestamp.fromDate(new Date()),
        updatedAt: Timestamp.fromDate(new Date()),
      });

      // Ajouter le candidat à la collection "Users"
      await setDoc(doc(db, 'Users', user.uid), {
        email: candidate.email,
        name: `${candidate.firstName} ${candidate.lastName}`,
        role: 'candidate',
        createdAt: Timestamp.fromDate(new Date()),
      });

      // Ajouter une activité de création de candidat
      await addActivity(`Ajout du candidat ${candidate.firstName} ${candidate.lastName}`);

      // Déconnexion de l'utilisateur actuel (si nécessaire)
      // await signOut(auth);

      // Rediriger vers la liste des candidats
      navigate('/candidates');
    } catch (err) {
      console.error("Erreur lors de l'ajout du candidat: ", err);
      setError('Erreur lors de l\'ajout du candidat. Veuillez vérifier que l\'e-mail n\'est pas déjà utilisé.');
    }
  };

  return (
    <Box sx={styles.container}>
      <Typography variant="h5" gutterBottom>Ajouter un Nouveau Candidat</Typography>
      {error && <Typography variant="body1" color="error">{error}</Typography>}
      <form onSubmit={handleSubmit} style={styles.form}>
        <TextField
          label="Prénom"
          name="firstName"
          value={candidate.firstName}
          onChange={handleChange}
          required
          fullWidth
          margin="normal"
        />
        <TextField
          label="Nom"
          name="lastName"
          value={candidate.lastName}
          onChange={handleChange}
          required
          fullWidth
          margin="normal"
        />
        <TextField
          label="Email"
          name="email"
          type="email"
          value={candidate.email}
          onChange={handleChange}
          required
          fullWidth
          margin="normal"
        />
        <TextField
          label="Mot de Passe"
          name="password"
          type="password"
          value={candidate.password}
          onChange={handleChange}
          required
          fullWidth
          margin="normal"
        />
        <TextField
          label="Confirmer le Mot de Passe"
          name="confirmPassword"
          type="password"
          value={candidate.confirmPassword}
          onChange={handleChange}
          required
          fullWidth
          margin="normal"
        />
        {/* Section Voeux */}
        <FormControl fullWidth margin="normal">
          <InputLabel id="voeux-label">Vœux (Formations)</InputLabel>
          <Select
            labelId="voeux-label"
            multiple
            value={candidate.voeux}
            onChange={handleVoeuxChange}
            input={<OutlinedInput label="Vœux (Formations)" />}
            renderValue={(selected) => selected.map(id => {
              const formation = formations.find(f => f.id === id);
              return formation ? formation.title : id;
            }).join(', ')}
          >
            {formations.map((formation) => (
              <MenuItem key={formation.id} value={formation.id}>
                <Checkbox checked={(candidate.voeux || []).indexOf(formation.id) > -1} />
                <ListItemText primary={formation.title} />
              </MenuItem>
            ))}
          </Select>
          <Typography variant="caption">Sélectionnez jusqu'à {maxVoeux} vœux.</Typography>
        </FormControl>
        {/* Case à cocher Espace Candidat Activé */}
        <FormControlLabel
          control={
            <Checkbox
              checked={candidate.espaceCandidatActive}
              onChange={handleChange}
              name="espaceCandidatActive"
              color="primary"
            />
          }
          label="Espace Candidat Activé"
        />
        <Button type="submit" variant="contained" color="primary" sx={styles.button}>
          Ajouter
        </Button>
      </form>
    </Box>
  );
};

const styles = {
  container: {
    padding: '32px',
    background: '#fff',
    borderRadius: '8px',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12)',
    maxWidth: '600px',
    margin: '0 auto',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    marginTop: '16px',
  },
};

export default AddCandidate;
